
  import { Component, Vue } from "vue-property-decorator";
  import { bus } from "@/main";
  import { WorkStatus } from "@/store/modules/activity.store";

  @Component({
    components: {
      LayoutModal: () => import("@/layouts/components/modal.vue"),
    },
  })
  export default class ActivityTransitionFailedModal extends Vue {
    show = false;

    params: { status: Array<WorkStatus | string[]>; activityId: number | null } = { status: [], activityId: null };

    mounted() {
      bus.$off("show-transition-failed");
      bus.$on("show-transition-failed", (params: { status: Array<WorkStatus | string[]>; activityId: number }) => {
        this.show = true;
        this.params = params;
      });
    }

    missingFieldSortOrder = [
      "building.type",
      "building.state",
      "building.construction_year",
      "ground_area",
      "livable_area",
      "features.bedrooms",
      "features.bathrooms",
      "features.has_garage",
      "features.has_parking",
      "features.has_garden",
      "features.has_terrace",
      "features.furnished",
      "sale.offer_from",
    ];

    get fromStatus(): WorkStatus {
      return this.params.status[0] as WorkStatus;
    }

    get toStatus(): WorkStatus {
      return this.params.status[1] as WorkStatus;
    }

    get reasons(): Array<"NEEDS_ADMIN" | "NO_SELLERS" | "NO_APPOINTMENTS" | "NO_COMMISSION" | "MISSING_FIELDS" | "NO_FOLLOWUP_TASKS"> {
      if (!this.params) {
        return [];
      }

      return this.params.status[2] as Array<"NEEDS_ADMIN" | "NO_SELLERS" | "NO_APPOINTMENTS" | "NO_COMMISSION" | "MISSING_FIELDS">;
    }

    get reasonParams(): any {
      if (!this.params) {
        return [];
      }

      return this.params.status[3];
    }

    get primaryReason() {
      if (!this.reasons) {
        return "";
      }

      return this.reasons[0];
    }

    get title() {
      if (this.primaryReason === "MISSING_FIELDS") {
        return "Er ontbreken verplichte velden";
      }

      return "Fase wijzigen niet toegestaan.";
    }

    get isDesktop() {
      // @ts-ignore
      return this.$root.isDesktop;
    }

    goTo() {
      switch (this.primaryReason) {
        case "NO_SELLERS":
          this.$router.push({ name: "dossier-detail", params: { id: `${this.params.activityId}` }, query: { tab: "contacten", pulse: "sellers" } });
          break;
        case "NO_APPOINTMENTS":
          this.$router.push({ name: "dossier-detail", params: { id: `${this.params.activityId}` }, query: { tab: "afspraken", pulse: "appointments" } });
          break;
        case "NO_COMMISSION":
          this.$router.push({ name: "dossier-settings", params: { id: `${this.params.activityId}` }, query: { pulse: "comission" } });
          break;
        case "NO_FOLLOWUP_TASKS":
          this.$router.push({ name: "dossier-detail", params: { id: `${this.params.activityId}` }, query: { tab: "taken" } });
          break;
        case "MISSING_FIELDS":
          const firstMissingField = this.getSortedReasonParams(0)[0];
          const routeName = this.isDesktop ? "dossier-property-create-desktop" : "dossier-property-create";

          switch (firstMissingField) {
            case "building.type":
            case "building.state":
              this.$router.push({ name: routeName, params: { id: `${this.params.activityId}` }, hash: "#building" });
              break;
            case "building.construction_year":
              this.$router.push({ name: routeName, params: { id: `${this.params.activityId}` }, hash: "#buildingYear", query: { pulse: "construction_year" } });
              break;
            case "ground_area":
            case "livable_area":
            case "features.bedrooms":
            case "features.bathrooms":
            case "features.has_garage":
            case "features.has_parking":
            case "features.has_garden":
            case "features.furnished":
            case "features.has_terrace":
              this.$router.push({ name: routeName, params: { id: `${this.params.activityId}` }, hash: "#features" });
              break;
            case "sale.offer_from":
              this.$router.push({ name: routeName, params: { id: `${this.params.activityId}` }, hash: "#general", query: { pulse: "price" } });
              break;
            default:
              this.$router.push({ name: routeName, params: { id: `${this.params.activityId}` } });
          }
          break;
      }

      this.handleClose();
    }

    getSortedReasonParams(reasonIndex: number) {
      if (reasonIndex === undefined) {
        return [];
      }

      if (!this.reasonParams[reasonIndex]) {
        return [];
      }

      let params = [...this.reasonParams[reasonIndex]];

      return params.sort((a: string, b: string) => this.missingFieldSortOrder.indexOf(a) - this.missingFieldSortOrder.indexOf(b));
    }

    handleClose() {
      this.show = false;
    }
  }
